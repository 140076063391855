<template>
    <div
        :id="`preview_view_${mode}`"
        class="preview-view"
        :class="`preview-view_${mode}`">
        <!-- 背景 -->
        <div
            class="preview-view-background"
            :style="getBackgroundStyle(loginPageInfo)"
            @click="handleBackgroundClick">
            <video
                v-if="loginPageInfo.background && loginPageInfo.background.type === 'video' && loginPageInfo.background.video"
                class="preview-view-background-video"
                :src="loginPageInfo.background.video"
                autoplay="autoplay" loop="loop" muted="muted"
                :controls="false" controlsList="nodownload" disablePictureInPicture
            ></video>
        </div>
        <!-- 组件拖拽遮罩 -->
        <div v-if="mode === 'edit'" v-show="dragMaskShow" class="preview-view-dragMask"></div>
        <!-- 登录框 -->
        <login-box
            v-if="loginPageInfo.theme && loginPageInfo.loginBox"
            :mode="mode"
            :templateInfo="templateInfo"
            :loginPageInfo="loginPageInfo"
            :wheelZoomRatio="wheelZoomRatio"
            :activatedItem="activatedItem"
            :loginForm="loginForm"
            :loginRules="loginRules"
            :loginButtonLoading="loginButtonLoading"
        ></login-box>
        <!-- 组件 -->
        <comp-item
            v-for="item in loginPageInfo.comps"
            :key="item.id"
            :mode="mode"
            :viewComparedStandardZoomRatio="viewComparedStandardZoomRatio"
            :wheelZoomRatio="wheelZoomRatio"
            :activatedItem="activatedItem"
            :comp="item"
        ></comp-item>
    </div>
</template>

<script>
import LoginBox from './PreviewContainer/LoginBox/index.vue'
import CompItem from './PreviewContainer/CompItem/index.vue'

export default {
    name: 'PreviewView',
    components: {
        LoginBox,
        CompItem
    },
    props: {
        mode: {
            type: String,
            required: true
        },
        // 视图相较于基准宽度的缩放比例
        viewComparedStandardZoomRatio: {
            type: Number,
            required: true
        },
        templateInfo: {
            type: Object,
            required: true
        },
        loginPageInfo: {
            type: Object,
            required: true
        },
        // 浏览器滚轮缩放率
        wheelZoomRatio: {
            type: Number,
            default: 100
        },
        // 组件拖拽遮罩显示状态
        dragMaskShow: {
            type: Boolean,
            default: false,
        },
        activatedItem: {
            type: String,
            default: ""
        },
        loginForm: {
            type: Object,
            default() {
                return {
                    curLoginMethod: '', // 当前登录方式
                    userName: '', // 账号
                    password: '', // 密码
                    phoneNumber: '', // 手机号
                    smsCode: '', // 短信验证码
                    smsCodeCountdown: 0, // 短信验证码倒计时
                    code: '', // 图片验证码
                    codeUrl: '', // 验证码图片
                    rememberPassword: false // 记住密码
                };
            }
        },
        loginRules: {
            type: Object,
            default() {
                return {};
            }
        },
        loginButtonLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        getBackgroundStyle(loginPageInfo) {
            console.log(loginPageInfo,'[loginPageInfo]')
            // 预览区域背景色
            // let previewViewDom = document.getElementById(`preview_view_${this.mode}`)
            // if (previewViewDom) {
            //     previewViewDom.style.background = this.mode === 'edit' ? 'transparent' : '#000'
            // }

            if (!loginPageInfo || !loginPageInfo.background) {
                return {}
            }

            if (loginPageInfo.background.type === 'picture') { // 图片背景
                // 预览区域背景色
                // this.$nextTick(() => {
                //     setTimeout(() => {
                //         previewViewDom = document.getElementById(`preview_view_${this.mode}`)
                //         if (previewViewDom) {
                //             previewViewDom.style.background = '#000'
                //         }
                //     }, 300);
                // });

                // 填充方式
                let backgroundFillMethod = {}
                if (loginPageInfo.background.fillMethod === 'cover') { // 填充
                    backgroundFillMethod = {
                        'background-size': 'cover',
                        'background-position': 'center',
                        'background-repeat': 'no-repeat'
                    }
                } else if (loginPageInfo.background.fillMethod === 'auto') { // 适应
                    backgroundFillMethod = {
                        'background-size': 'auto',
                        'background-position': 'center',
                        'background-repeat': 'no-repeat'
                    }
                } else if (loginPageInfo.background.fillMethod === 'repeat') { // 平铺
                    backgroundFillMethod = {
                        'background-size': 'auto',
                        'background-position': 'center',
                        'background-repeat': 'repeat'
                    }
                }

                // 背景图
                let images = this._.cloneDeep(loginPageInfo.background.images)
                // loginPageInfo.background.default初始时是接口返回的值，接口返回值之前是/img/background...
                // 后改为将图片放在minio上，但是接口返回值尚未修改，所以此处添加判断，如果是以/img/开头的就使用第一张默认图
                console.log(loginPageInfo.background.default,'images===',images)
                if (images.length === 0) {
                    images = [
                        loginPageInfo.background.default.startsWith('/img/') ? 
                        'https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/my/background1.png' : 
                        loginPageInfo.background.default
                    ]
                }
                // console.log("[getBackgroundStyle]images:", images)
                if (images.length === 1) {
                    return Object.assign(backgroundFillMethod, {
                        'background-image': 'url(' + images[0] + ')',
                    })
                } else {
                    loginPageInfo.background.carouselSpeed = parseInt(loginPageInfo.background.carouselSpeed) // 防止carouselSpeed不是数字
                    let carouselSpeed = loginPageInfo.background.carouselSpeed
                    let animationTime = (carouselSpeed + 2) * images.length
                    // console.log("[getBackgroundStyle]animationTime:", animationTime)
                    let secondRatio = 1 / animationTime * 100
                    let backgrounds = ""
                    // let transitionEffectShow = "opacity:0;"
                    // let transitionEffectHide = "opacity:1;"
                    let transitionEffectShow = "filter:blur(80px);"
                    let transitionEffectHide = "filter:blur(0px);"
                    let background = []
                    let image
                    for (let i in loginPageInfo.background.images) {
                        i = parseInt(i)
                        image = loginPageInfo.background.images[i]
                        background.push(`url("${image}")`)
                        let startProgress = i * (carouselSpeed + 2) * secondRatio
                        backgrounds += ` ${startProgress}%{`
                        backgrounds += `background-image:url("${image}");`
                        backgrounds += transitionEffectShow
                        backgrounds += "} "

                        backgrounds += ` ${startProgress + secondRatio}%{`
                        backgrounds += `background-image:url("${image}");`
                        backgrounds += transitionEffectHide
                        backgrounds += "} "

                        backgrounds += ` ${startProgress + (carouselSpeed + 1) * secondRatio}%{`
                        backgrounds += `background-image:url("${image}");`
                        backgrounds += transitionEffectHide
                        backgrounds += "} "

                        backgrounds += ` ${startProgress + (carouselSpeed + 2) * secondRatio - 0.0001}%{`
                        backgrounds += `background-image:url("${image}");`
                        backgrounds += transitionEffectShow
                        backgrounds += "} "
                    }
                    backgrounds += ` 100%{background-image:url("${image}");`
                    backgrounds += transitionEffectShow
                    backgrounds += "} "
                    // console.log("[getBackgroundStyle]backgrounds:", backgrounds)

                    let animationName = 'carousel'
                    let styleSheet = document.styleSheets[0];
                    // console.log("[getBackgroundStyle]styleSheet:", styleSheet)
                    let animationIndex = -1
                    for (let i in styleSheet.cssRules) {
                        if (styleSheet.cssRules[i].name && styleSheet.cssRules[i].name === animationName) {
                            animationIndex = parseInt(i)
                            break
                        }
                    }
                    if (animationIndex > -1) {
                        styleSheet.deleteRule(styleSheet.cssRules[animationIndex])
                    }
                    styleSheet.insertRule(` @keyframes ${animationName} { ${backgrounds} }`);
                    // styleSheet.insertRule(` @-webkit-keyframes ${animationName} { ${backgrounds} }`); /* Safari 和 Chrome */
                    // styleSheet.insertRule(` @-moz-keyframes ${animationName} { ${backgrounds} }`); /* Firefox */
                    // styleSheet.insertRule(` @-o-keyframes ${animationName} { ${backgrounds} }`); /* Opera */

                    return Object.assign(backgroundFillMethod, {
                        'background-image': background.join(','),
                        'animation': `${animationName} ${animationTime}s linear 0 infinite normal`,
                        '-webkit-animation': `${animationName} ${animationTime}s linear 0s infinite normal`, /* Safari 和 Chrome */
                        '-moz-animation': `${animationName} ${animationTime}s linear 0 infinite normal`, /* Firefox */
                        '-o-animation': `${animationName} ${animationTime}s linear 0 infinite normal`, /* Opera */
                    })
                }
            } else if (loginPageInfo.background.type === 'video') { // 视频背景
                return {
                    'background': '#000'
                }
            } else if (loginPageInfo.background.type === 'color') { // 纯色背景
                return {
                    'background': loginPageInfo.background.color
                }
            }
        },
        handleBackgroundClick() {
            this.$eventDispatch("backgroundClick")
        }
    }
}
</script>

<style lang="scss" scoped>
.preview-view {
    position: relative;
    overflow: hidden;
    transform: translateZ(0);
    will-change: transform;
}
.preview-view_edit {
    background: transparent;
}
.preview-view_view {
    background: #000;
}

.preview-view-background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;

    .preview-view-background-video {
        width: 100%;
        height: 100%;
        object-fit: contain;
        z-index: 0;
    }
}

.preview-view-dragMask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1999;
    // background: rgba($color: #000000, $alpha: 0.5);
    background: transparent;
}
</style>
