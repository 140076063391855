<template>
    <div id="preview_full_screen" class="preview-full-screen">
        <i class="el-icon-error close-btn" @click="handleClose"></i>
        <preview-view
            :mode="mode"
            :viewComparedStandardZoomRatio="viewComparedStandardZoomRatio"
            :templateInfo="templateInfo"
            :loginPageInfo="loginPageInfo"
        ></preview-view>
    </div>
</template>

<script>
import PreviewView from './PreviewView.vue'

export default {
    name: 'PreviewFullScreen',
    components: {
        PreviewView
    },
    props: {
        mode: {
            type: String
        },
        viewComparedStandardZoomRatio: {
            type: Number
        },
        templateInfo: {
            type: Object
        },
        loginPageInfo: {
            type: Object
        }
    },
    data() {
        return {

        }
    },
    computed: {},
    watch: {},
    created() {
    },
    mounted() {},
    methods: {
        handleClose() {
            this.$emit('close');
        }
    }
}
</script>

<style lang="scss" scoped>
.preview-full-screen {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.close-btn {
    position: absolute;
    top: 50px;
    right: 50px;
    cursor: pointer;
    font-size: 40px;
    color: #A0A1A3;
    z-index: 99;
    opacity: 0.6;
}
</style>
